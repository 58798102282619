import { object } from "prop-types";

export const Constants = Object.freeze({
    title: 'title',
    titleLevel: 'titleLevel',
    classNames: 'classNames',
    callToActionLink: 'callToActionLink',
    callToActionLabel: 'callToActionLabel',
    buyNowModal: 'buyNowModal',
    description: 'description',
    disclaimer: 'disclaimer',
    disclaimerReference: 'disclaimerReference',
    disclaimerAdditional: 'disclaimerAdditional',
    eyebrow: 'eyebrow',
    backgroundAsset: 'backgroundAsset',
    mobileBackgroundAsset: 'mobileBackgroundAsset',
    anchorId: 'anchorId',
    hashChange: 'hashchange',
    resize: 'resize',
    close: 'close',
    play: 'play',
    mobile: 'mobile',
    desktop: 'desktop',
    chevronRight: 'chevronRight',
    blue: 'blue',
    smooth: 'smooth',
    backgroundImage: 'backgroundImage',
    mainAsset: 'mainAsset',
    name: 'name',
    empty: '',
    blocks: 'blocks',
    body: 'body',
    submit: 'submit',
    json: 'application/json',
    whiteText: 'white-text',
    white: 'white',
    content: 'content ',
    top: 'top',
    primaryColor: '#0057B8',
    scroll: 'scroll',
    ratingSummary: 'rating_summary',
    button: 'button',
    chevronDown: 'chevronDown',
    chevronTop: 'chevronTop',
    wideChevronDown: 'wideChevronDown',
    thinChevronDown: 'thinChevronDown',
    itemType: 'https://schema.org/Product',
    text: 'text',
    small: 'small',
    medium: 'medium',
    large: 'large',
    h1: 'h1',
    h2: 'h2',
    keydown: 'keydown',
    Tab: 'Tab',
    Escape: 'Escape',
    touchmove: 'touchmove',
    mediumLong: 'medium-long',
    registerPopup: 'registerPopup',
    pathDelimiter: '/',
    collectionHeroSection: 'collection-hero-section',
    collectionHeroSectionCta: 'collection-hero-section-cta',
    titleLinkName: 'title-link',
    kidsProductCategory: 'Kids Product Category',
    Loading: 'Loading',
    engPassword: 'Password',
    PrivacyLinkPlaceholder: '{{privacyLink}}',
    AdChoicesLinkPlaceholder: '{{adChoicesPlaceHolder}}'
});

export const OrganizationConstants = Object.freeze({
   brand: 'OralB',
   parentOrganization: 'Procter & Gamble'
});

export const AdditionalReadingBlockConstants = Object.freeze({
    additionalReadingBlock: 'additionalReadingBlock'
});

export const AnnouncementBarConstants = Object.freeze({
    disableDays: 'disableDays',
    AnnouncementBarDisableDate: 'AnnouncementBarDisableDate',
    closeAriaLabel: 'closeAriaLabel',
    close: Constants.close,
    classNames: Constants.classNames,
    callToActionLink: Constants.callToActionLink,
    callToActionLabel: Constants.callToActionLabel,
    description: Constants.description,
    ctaName: 'electric toothburshes',
});

export const AppDownloadBlockConstants = Object.freeze({
    title: Constants.title,
    classNames: Constants.classNames,
    eyebrow: Constants.eyebrow,
    backgroundAsset: Constants.backgroundAsset,
    mobileBackgroundAsset: Constants.mobileBackgroundAsset,
    downloadLinks: 'downloadLinks',
    mobile: Constants.mobile,
    desktop: Constants.desktop,
    body: Constants.body,
});

export const ArticleCategoryHeroBlockConstants = Object.freeze({
    title: Constants.title,
    titleLevel: Constants.titleLevel,
    classNames: Constants.classNames,
    eyebrow: Constants.eyebrow,
    body: Constants.body,
    backgroundAsset: Constants.backgroundAsset,
    mobileBackgroundAsset: Constants.mobileBackgroundAsset,
    ctas: 'ctas',
    chevronRight: Constants.chevronRight,
    disclaimer: Constants.disclaimer,
    blue: Constants.blue,
    mainAsset: 'mainAsset',
    mobileAsset: 'mobileAsset',
    textToTheRight: 'textToTheRight',
    backgroundColor: 'backgroundColor'
});

export const ArticleHeroBlockConstants = Object.freeze({
    classNames: Constants.classNames,
    readTimeLabel: 'readTimeLabel'
});

export const ImageConstants = Object.freeze({
    defaultImageWindWidth: 450,
    defaultWidth: 400,
    defaultDesktopWidth:768,
    dpr_1440:'f_auto,q_auto,w_1440',
    mobile_max_width: '767px',
    desktop_min_width: '768px'
});

export const ArticlePopUpConstants = Object.freeze({
    title: Constants.title,
    body: 'body',
    facebookLabel: 'facebookLabel',
    twitterLabel: 'twitterLabel',
    closeOverlayLabel: 'closeOverlayLabel'
});

export const BrushConfiguratorConstants = Object.freeze({
    dataName: 'data-name',
    smooth: Constants.smooth,
    center: 'center',
});

export const CharacterBlockConstants = Object.freeze({
    title: Constants.title,
    titleLevel: Constants.titleLevel,
    classNames: Constants.classNames,
    eyebrow: Constants.eyebrow,
    description: Constants.description,
    backgroundImage: Constants.backgroundImage,
    mobileBackgroundImage: 'mobileBackgroundImage',
    characterLogo: 'characterLogo',
    mobileCharacterLogo: 'mobileCharacterLogo',
    maxWidth: '(max-width: 667px)',
    anchorId: Constants.anchorId,
});

export const CollectionTabsBlockConstants = Object.freeze({
    smooth: Constants.smooth,
    hash: '#',
});

export const ComparisonChartBlockConstants = Object.freeze({
    row: 'row',
    table: 'table',
    rowheader: 'rowheader',
    columnheader: 'columnheader',
    cell: 'cell',
    noValueLabel: 'noValueLabel',
    top: Constants.top,
    chevronRight: Constants.chevronRight,
    jumpToConfiguratorLink: 'jumpToConfiguratorLink',
    series: 'series',
    title: Constants.title,
    description: Constants.description,
    ancherId: Constants.anchorId,
    enableBuynowCta: 'enableBuynowCta',
    enableScrollHintScript: 'enableScrollHintScript',
    parentElementWidth: 'parentElementWidth',
    classNames: 'classNames',
    comparisonChartView: 'comparisonChartView',
    linear: 'linear',
    grid: 'grid',
    altTextPrefixFeatureImage: 'comparison-chart-feature',
    pro:'pro',
    vitality: 'vitality',
    etb: 'etb',
    ariaLabel: 'Select first IO series'
});

export const ContactUsBlockConstants = Object.freeze({
    classNames: Constants.classNames,
    contactUs: 'contact-us'
});

export const ContentConstants = Object.freeze({
    playing: 'playing',
    play: 'play',
    pause: 'pause',
    pauseLabel: 'pauseLabel',
    playLabel: 'playLabel',
    resetLabel: 'resetLabel',
    openTranscriptLabel: 'openTranscriptLabel',
    closeTranscriptLabel: 'closeTranscriptLabel',
    ended: 'ended',
    Src: 'Src',
    Dest: 'Dest',
    x: 'x',
    y: 'y',
    White: 'White',
    white: Constants.white,
    blue: Constants.blue,
    transparentWithBorder: 'Transparent With Border',
    blueBorderBtn: 'ob-blue-border-btn',
    clicPage: 'container-brush',
    clicPageHeroClass: 'clic-hero',
    clicFavoriteOptionClass: 'favourite-options',
    chevronRight: Constants.chevronRight,
    primaryGrey: 'primaryGrey',
    callToActionLink: Constants.callToActionLink,
    callToActionLabel: Constants.callToActionLabel,
    callToActionAriaLabel:'callToActionAriaLabel',
    callToActionTitle: 'callToActionTitle',
    surtitle: 'surtitle',
    description: Constants.description,
    classNames: Constants.classNames,
    callToActionIsALink: 'callToActionIsALink',
    ctaButtonMovedBelowMainAssest: 'ctaButtonMovedBelowMainAssest',
    descriptionMovedBelowMainAsset: 'descriptionMovedBelowMainAsset',
    title: Constants.title,
    titleLevel: Constants.titleLevel,
    backgroundColor: 'backgroundColor',
    textContainerBackgroundColor: 'textContainerBackgroundColor',
    textContainerBackgroundOpacity: 'textContainerBackgroundOpacity',
    textContainerHasPadding: 'textContainerHasPadding',
    blueEyebrow: 'blueEyebrow',
    disclaimer: Constants.disclaimer,
    disclaimerReference: Constants.disclaimerReference,
    disclaimerAdditional: Constants.disclaimerAdditional,
    textsImage: 'textsImage',
    cloudinaryVideo: 'cloudinaryVideo',
    devicesConfigurations: 'devicesConfigurations',
    deviceConfigurations: 'deviceConfigurations',
    isMedium: 'isMedium',
    blockMinHeight: 'blockMinHeight',
    blockMarginTop: 'blockMarginTop',
    blockMarginBottom: 'blockMarginBottom',
    blockMarginRight: 'blockMarginRight',
    blockMarginLeft: 'blockMarginLeft',
    blockPadding: 'blockPadding',
    textAlign: 'textAlign',
    textContainerWidth: 'textContainerWidth',
    titleWidth: 'titleWidth',
    descriptionWidth: 'descriptionWidth',
    textContainerHorizontalAlignment: 'textContainerHorizontalAlignment',
    textContainerVerticalAlignment: 'textContainerVerticalAlignment',
    textContainerOffset: 'textContainerOffset',
    textContainerMarginRight: 'textContainerMarginRight',
    textContainerMarginLeft: 'textContainerMarginLeft',
    textContainerMarginBottom: 'textContainerMarginBottom',
    mainAsset: 'mainAsset',
    secondaryAsset: 'secondaryAsset',
    mainAssetBelowOrAboveText: 'mainAssetBelowOrAboveText',
    mainAssetMaxWidth: 'mainAssetMaxWidth',
    mainAssetMaxHeight: 'mainAssetMaxHeight',
    mainAssetHeight: 'mainAssetHeight',
    mainAssetMarginTop: 'mainAssetMarginTop',
    mainAssetMarginRight: 'mainAssetMarginRight',
    mainAssetOffsetBottom: 'mainAssetOffsetBottom',
    mainAssetOffsetLeft: 'mainAssetOffsetLeft',
    mainAssetScale: 'mainAssetScale',
    backgroundAsset: 'backgroundAsset',
    mainAssetHorizontalAlignment: 'mainAssetHorizontalAlignment',
    mainAssetVerticalAlignment: 'mainAssetVerticalAlignment',
    backgroundPosition: 'backgroundPosition',
    sectionOffsetTop: 'sectionOffsetTop',
    mainAssetParallax: 'mainAssetParallax',
    mainAssetParallaxSetting: 'mainAssetParallaxSetting',
    textParallax: 'textParallax',
    textParallaxSetting: 'textParallaxSetting',
    textParallaxConfiguration: 'textParallaxConfiguration',
    assetParallaxConfiguration: 'assetParallaxConfiguration',
    textShowAnimation: 'textShowAnimation',
    textFadeIn: 'textFadeIn',
    textColor: 'textColor',
    respectImageAspectRatio: 'respectImageAspectRatio',
    titleFontSize: 'titleFontSize',
    small: Constants.small,
    medium: Constants.medium,
    large: Constants.large,
    ctaBackgroundColor: 'ctaBackgroundColor',
    aboveText: 'above text',
    belowText: 'below text',
    hasDivider: 'hasDivider',
    buyNowModal: 'buyNowModal',
    isRemoveParallax:'isRemoveParallax',
    defaultWidth: 350,
    defaultWindWidth: 400,
    linkType: 'linkType',
    External: 'External',
    TargetBlank: '_blank',
    isRemoveParallax: 'isRemoveParallax',
    isCarouselBlock: 'isCarouselBlock',
    relatedBlocks: 'blocks',
    ctaName: 'collection-cta',
    replay: 'replay',
});

export const ContentVideoConstants = Object.freeze({
    Tab: Constants.Tab,
    keydown: Constants.keydown,
    description: Constants.description,
    disclaimer: Constants.disclaimer,
    mobileLayout: 'mobileLayout',
    desktopLayout: 'desktopLayout',
    whiteText: Constants.whiteText,
    backgroundImage: 'background-image',
    title: Constants.title,
    titleLevel: Constants.titleLevel,
    classNames: Constants.classNames,
    textContainerMarginBottom: 'textContainerMarginBottom',
    textContainerMarginTop: 'textContainerMarginTop',
    playIconColor: 'playIconColor',
    backgroundColor: 'backgroundColor',
    playLabel: 'playLabel',
    closeLabel: 'closeLabel',
    close: Constants.close,
    play: Constants.play,
    pauseLabel: 'pauseLabel',
    resetLabel: 'resetLabel',
    openTranscriptLabel: 'openTranscriptLabel',
    closeTranscriptLabel: 'closeTranscriptLabel',
    white: Constants.white,
    whiteHexThreeChars: '#fff',
    whiteHexFull: '#ffffff',
    surtitle: 'surtitle',
    blueEyebrow: 'blueEyebrow'
});

export const FAQBlockConstants = Object.freeze({
    switch: 'switch',
    button: Constants.button,
    minus6: 'minus6',
    plus: 'plus',
    arrowRight: 'ovalArrowRight',
    popularSentence: 'Popular topics others look for',
    searchResultsTitle: 'We found 6 results for Warranty',
    obSupport: 'Oral-B Support',
    Previous: 'Previous',
    Next: 'Next',
    of: 'of'
});

export const FooterConstants = Object.freeze({
    resize: Constants.resize,
    copyright: 'copyright',
    copyrightAt: '&copy;',
    countryCode: 'JP',
    lang: 'ja',
    privacyLink: 'privacyLink',
    adChoicesFooter: 'adchoices-footer',
    footerUtilityHierarchy: 'Hierarchy-Footer-Utility'
});

export const GalleryBlockConstants = Object.freeze({
    cloudinaryImage: 'cloudinaryImage',
    classNames: Constants.classNames,
    title: Constants.title,
    whiteText: Constants.whiteText,
    anchorId: Constants.anchorId,
    seeMoreButtonLabel: 'seeMoreButtonLabel',
    seeLessButtonLabel: 'seeLessButtonLabel',
    minus6: 'minus6',
    blue: Constants.blue,
    plus: 'plus',
    full: 'Full',
    large: 'Large',
    small: 'Small'
})

export const InTheBoxBlockConstants = Object.freeze({
    whiteText: Constants.whiteText,
    body: 'body',
    classNames: 'classNames',
    items: 'items',
    anchorId: Constants.anchorId,
    inTheBoxImage: 'inTheBoxImage',
    title: Constants.title,
    titleLevel: Constants.titleLevel,
});

export const LanguageSelectionConstants = Object.freeze({
    primaryColor: Constants.primaryColor,
    smooth: Constants.smooth,
    keydown: Constants.keydown,
    title: Constants.title,
    regions: 'regions',
    currentLanguage: 'currentLanguage',
    jumpToLabel: 'jumpToLabel',
    closeLanguageSelectionLabel: 'closeLanguageSelectionLabel',
    wideChevronDown: 'wideChevronDown',
    close: Constants.close,
    countryLanguageSelection: 'Country and Language selection',
    countryLanguageAriaLabel:'languageSelectionAriaLabel'
});

export const LayoutConstants = Object.freeze({
    classNames: Constants.classNames,
    zoneContestCode: 'zone-contest',
    zoneThankYouCode: 'zone-thankyou',
    paddingValue:'66px'
});

export const LegacyProductHighlightsConstants = Object.freeze({
    isActive: 'is-active',
    empty: Constants.empty,
    minus6: 'minus6',
    blue: Constants.blue,
    button: Constants.button,
    plus: 'plus',
    seeLess: 'SEE LESS',
    seeMore: 'SEE MORE',
    highlights: ' highlights',
    plusSymbol: '+',
    minusSymbol: '-',
    arrowUp: 'ArrowUp',
    arrowDown: 'ArrowDown',
    text: Constants.text,
    prev: 'prev',
    next: 'next',
    smooth: 'smooth',
    nearest: 'nearest',
    center: 'center',
    buyNowTop: '_TOP',
    highlights: 'highlights'  
});

export const MainMenuConstants = Object.freeze({
    primaryColor: Constants.primaryColor,
    mousedown: 'mousedown',
    blur: 'blur',
    resize: Constants.resize,
    scroll: Constants.scroll,
    keydown: Constants.keydown,
    close: Constants.close,
    Open: 'Open',
    Close: 'Close',
    child: 'child',
    productByNeed: 'product-by-need',
    productByType: 'product-by-type',
    whyOralB: 'why-oral-b',
    conditions: 'conditions',
    lifeStages: 'life-stages',
    wideChevronDown: 'wideChevronDown',
    chevronDown: Constants.chevronDown,
    plus: 'plus',
    minus6: 'minus6',
    mainMenuLabel: 'mainMenuLabel',
    hierarchy: 'hierarchy',
    classNames: Constants.classNames,
    hamburger: 'hamburger',
    closeMenuLabel: 'closeMenuLabel',
    openMenuLabel: 'openMenuLabel',
    searchLabel: 'searchLabel',
    returnToMainMenuLabel: 'returnToMainMenuLabel',
    arrowLeft: 'arrowLeft',
    searchDataActionDetail: 'Open Search Panel',
    panel: 'panel',
    pullDown: 'pull-down',
    collapsed: 'collapsed',
    expanded: 'expanded',
    expanding: 'expanding',
    escape: 'Escape'
});

export const MarkdownTextConstants = Object.freeze({
    script: 'script',
    text: 'text'
})

export const MetaConstants = Object.freeze({
    https: 'https://'
});

export const MiniNavigationConstants = Object.freeze({
    hierarchy: 'hierarchy',
    classNames: Constants.classNames,
    backgroundGrey: 'backgroundGrey',
    href: 'href',
    smooth: Constants.smooth,
    chevronDown: Constants.chevronDown,
    resize: Constants.resize,
    target: 'target'
})

export const ComponentFactoryConstants = Object.freeze({
    Zone: 'Zone',
    Layout: 'Layout',
    Content: 'ContentBlock',
    ContentVideo: 'contentVideo',
    RichText: 'richTextBlock',
    RichTextLink: 'link',
    MarkdownText: 'markdownTextBlock',
    MainMenu: 'mainMenuBlock',
    Footer: 'footerBlock',
    LanguageSelection: 'languageSelectionBlock',
    PageNavigation: 'pageNavigationBlock',
    TabbedContent: 'tabbedContentBlock',
    AnnouncementBar: 'announcementBarBlock',
    SideBySideContainer: 'sideBySideContainerBlock',
    SeriesSelector: 'seriesSelectorBlock',
    MiniNavigation: 'miniNavigationBlock',
    PromoBlock: 'promoBlock',
    SpotlightSlider: 'spotlightSlider',
    SpotlightContentVideo: 'spotlightContentVideo',
    SpotlightContentBlock: 'spotlightContentBlock',
    SpotlightStickyBar: 'spotlightStickyBarBlock',
    InTheBoxBlock: 'inTheBoxBlock',
    ProductGridBlock: 'productGridBlock',
    CollectionTabsBlock: 'collectionTabsBlock',
    ProductHighlights: 'productHighlightBlock',
    FeaturesTabBlock: 'featuresTabBlock',
    BrandGuaranteeBlock: 'brandGuaranteeBlock',
    GalleryBlock: 'galleryBlock',
    FAQBlock: 'faqBlock',
    BrushConfigurator: 'brushConfiguratorBlock',
    ComparisonChartBlock: 'comparisonChartBlock',
    UserQuoteBlock: 'userQuoteBlock',
    SpotlightWaitlistBlock: 'spotlightWaitlistBlock',
    SpotlightPreorderBlock: 'spotlightPreOrderBlock',
    CollectionTabsDropdown: 'collectionSegmentTabsDropdownBlock',
    ProductSubNav: 'productSubNav',
    CharacterBlock: 'characterBlock',
    ProductRecommenderBlock: 'productRecommenderBlock',
    ShowcaseBlock: 'showcaseBlock',
    ArticleHeroBlock: 'articleHeroBlock',
    SkipNavigationBlock: 'skipNavigationBlock',
    AppDownloadBlock: 'appDownloadBlock',
    ArticleCategoryHeroBlock: 'articleCategoryHeroBlock',
    RecentlyViewedProductsBlock: 'recentlyViewedProductsBlock',
    AdditionalReadingBlock: 'additionalReadingBlock',
    ProductOverlay: 'productOverlay',
    ErrorBlock: 'errorBlock',
    ArticleBody: 'articleBody',
    CloudinaryImage: 'cloudinaryImage',
    YoutubeVideo: 'youtubeVideoBlock',
    HTMLVideoPlayer: 'hTMLVideoBlock',
    ResponsiveImage: 'responsiveImageBlock',
    ContactUsBlock: 'contacUsBlock',
    LoginBlock: 'loginBlock',
    ForgotPasswordBlock: 'forgotPasswordBlock',
    ResetPasswordBlock: 'resetPasswordBlock',
    CreateAccountBlock: 'createAccountBlock',
    PypestreamChat: 'pypestreamChat',
    CampaignIframeOverlay: 'campaignIframeOverlay',
    CosponsorshipBlock: 'cosponsorshipBlock',
    SearchBlock: 'search',
    PressQuoteBlock: 'pressQuoteBlock',
    RegisterProductBlock: 'registerProductBlock',
    CongratulationsBlock: 'congratulationBlock',
    ProfileBlock: 'profileBlock',
    ChangePasswordBlock: 'changePasswordBlock',
    Awards: 'awardsBlock',
    LoginNavigationBlock: 'loginNavigationBlock',
    DesignedByDentist: 'designedByDentistBlock',
    LegacyArticleHeroBlock: 'legacyArticleHeroBlock',
    ContentDetailsBlock: 'contentDetailsBlock',
    ConsentBlock: 'ConsentBlock',
    ThreeColumnsUserQuoteBlock: 'threeColumnsQuoteBlock',
    ArticlePopUp: 'articlePopUp',
    ArticleShare: 'articleShareBlock',
    LegacyProductHighlightBlock: 'legacyProductHighlightBlock',
    ArticleListBlock: 'articleListBlock',
    ArticleTableBlock: 'articleTableBlock',
    SpotlightContestBlock: 'spotlightContestBlock',
    SpotlightContestThankYouBlock: 'spotlightContestThankYouBlock',
    ProductLegacyBody: 'productLegacyBody',
    FaqDetailsBlock: 'faqDetailsBlock',
    Disclaimer: 'disclaimer',
    ProductVariantsRecommanderBlock: 'productVariantsRecommanderBlock',
    SitemapBlock: 'sitemapBlock',
    SitemapPage: 'SitemapPage',
    HTMLBlock: 'htmlBlock',
    SpotlightExperienceBlock: 'spotlightExperienceBlock',
    DoubleOptInVerificationBlock: 'doubleOptInVerificationBlock',
    ClicCarousel: 'ClicCarousel',
    ClicVideo: 'ClicVideo',
    SeriesComparison:'blockIOComparisionBlock',
    SliderCarousel: 'sliderCarousel',
    InstagramPost: 'InstagramPost',
    mediaUpper: '(min-width: 1200px)',
    mediaLower: '(min-width: 426px)',
    dprUpper: 'f=center&fit=fill&w=460&h=260',
    dprMedium: 'f=center&fit=fill&w=272&h=150',
    dprLower: 'f=center&fit=fill&w=124&h=150',
    dprAuto: 'f=center&fit=fill&w=124&h=150',
    BreadCrumbs: 'breadCrumbs',
    AccordionBlock: 'accordionBlock',
    MapBlock: 'mapBlock',
    YoutubeVideoIframe: 'youtubeVideoIframe'
});

export const ZoneConstants = Object.freeze({
    zone: 'zone ',
    classNames: Constants.classNames,
    role: 'role',
    ariaLabel: 'ariaLabel',
    backgroundImage: Constants.backgroundImage,
    id: 'id',
    zoneSpotlightSliderBodyClassname: 'spotlight-body-zone',
    zoneSpotlightContestClassname: 'spotlight-zone-contest',
});

export const YoutubeVideoConstants = Object.freeze({
    percent: '%',
    title: 'Youtube',
    url: 'https://www.youtube.com/embed/',
    https: 'https:'
});

export const VideoPlayerConstants = Object.freeze({
    id: 'video-player',
    videoRecommendations: 'Video Recommendations overlay item information',
    videoRatioVolume: 0.3,
    descriptionButtonLowOpacity : 0.5,
    descriptionButtonHighOpacity : 1,
    domainHttp : 'https:',
    play:'playButton',
    pause:'pauseButton',
    mute:'muteButton',
    unmute:'unMuteButton',
});

export const UserQuoteConstants = Object.freeze({
    classNames: Constants.classNames,
    title: Constants.title,
    mainAsset: Constants.mainAsset,
    name: Constants.name,
    disclaimer: Constants.disclaimer,
    authorName: 'authorName',
    quote: 'quote',
    starRating: 'starRating',
    writeAReviewLabel: 'writeAReviewLabel',
    ratingSummary: Constants.ratingSummary,
    reviews: 'reviews',
    questions: 'questions',
    h1: Constants.h1,
    h2: Constants.h2,
    itemType: Constants.itemType,
    itemProp: Constants.name,
    displayUserReviews: 'displayUserReviews',
    reviewSection:'Write a review ',
    containerElement:'div'
});

export const UnderlineTabsNavigationConstants = Object.freeze({
    resize: Constants.resize,
    tabList: 'tablist',
    tab: 'tab'
});

export const TabbedContentConstants = Object.freeze({
    empty: Constants.empty,
    anchorName: 'anchorName',
    chevronDown: Constants.chevronDown,
    tab: 'tab-',
    blocks: Constants.blocks
});

export const StarRatingConstants = Object.freeze({
    star: 'star',
    starEmpty: 'starEmpty',
    starHalf: 'starHalf',
});

export const SpotlightWaitlistConstants = Object.freeze({
    error: 'An error occured',
    failedToRegister: ': Registration failed',
    title: Constants.title,
    body: Constants.body,
    callToActionLabel: 'callToActionLabel',
    legalText: 'legalText',
    emailLimitExceedLabel: 'emailLimitExceedLabel',
    invalidLocaleLabel: 'invalidLocaleLabel',
    serviceUnavailableLabel: 'serviceUnavailableLabel',
    formLabels: 'formLabels',
    classNames: Constants.classNames,
    anchorId: Constants.anchorId,
    backgroundAsset: Constants.backgroundAsset,
    mobileBackgroundAsset: Constants.mobileBackgroundAsset,
    thankYouTitleLabel: 'thankYouTitleLabel',
    thankYouBackgroundAsset: 'thankYouBackgroundAsset',
    thankYouDescriptionLabel: 'thankYouDescriptionLabel',
    thankYouCallToAction: 'thankYouCallToAction',
    thankYouCallToActionLink: 'thankYouCallToActionLink',
    thankYouCallToActionIconName: 'thankYouCallToActionIconName',
    closeModalLabel: 'closeModalLabel',
    numberOfErrorsLabel: 'numberOfErrorsLabel',
    submit: Constants.submit,
    close: Constants.close,
    gcsCampaignParam: 'campaignId',
    gcsLocaleParam: 'locale',
    contentType: 'Content-Type',
    json: Constants.json,
    accessToken: 'access_token',
    system: 'GCS',
    chevronDown: Constants.chevronDown,
    countrySelectLabel: 'countrySelectLabel',
    countrySelectList: 'countrySelectList',
    countrySelectListDefaultOption: 'countrySelectListDefaultOption',
    countrySelectErrorMessage: 'countrySelectErrorMessage',
    countrySelectMention: 'countrySelectMention',
    modalLabelFirst: 'modalLabelFirst',
    modalLabelSecond: 'modalLabelSecond',
    addressTypeCode: 'addressTypeCode',
    alternateThankYouTitleLabel: 'alternateThankYouTitleLabel',
    alternateThankYouDescriptionLabel: 'alternateThankYouDescriptionLabel'
});

export const SpotlightContestConstants = Object.freeze({
    error: 'An error occured',
    failedToRegister: ': Sign up failed',
    title: Constants.title,
    contestTitle: 'contestTitle',
    signUpCallToActionLabel: 'signUpCallToActionLabel',
    emailLimitExceedLabel: 'emailLimitExceedLabel',
    invalidLocaleLabel: 'invalidLocaleLabel',
    serviceUnavailableLabel: 'serviceUnavailableLabel',
    formFields: 'formFields',
    classNames: Constants.classNames,
    anchorId: Constants.anchorId,
    backgroundAsset: Constants.backgroundAsset,
    mobileBackgroundAsset: Constants.mobileBackgroundAsset,
    oralBLogoAsset: 'oralBLogo',
    backToOralbLink: 'backToOralbLink',
    ioLogoAsset: 'ioLogo',
    formOptinInputText: 'formOptinInputText',
    formOwnETBInputText: 'formOwnETBInputText',
    formLegalText: 'formLegalText',
    socialLinks: 'socialLinks',
    redirectDestination: 'redirectDestination',
    copyrightLabel: 'copyrightLabel',
    thankYouTitleLabel: 'thankYouTitleLabel',
    thankYouBackgroundAsset: 'thankYouBackgroundAsset',
    thankYouDescriptionLabel: 'thankYouDescriptionLabel',
    thankYouCallToAction: 'thankYouCallToAction',
    thankYouCallToActionLink: 'thankYouCallToActionLink',
    thankYouCallToActionIconName: 'thankYouCallToActionIconName',
    numberOfErrorsLabel: 'numberOfErrorsLabel',
    submit: Constants.submit,
    close: Constants.close,
    GcsURL: '/registration?access_token=',
    gcsCampaignParam: 'campaignId',
    gcsLocaleParam: 'locale',
    contentType: 'Content-Type',
    json: Constants.json,
    accessToken: 'access_token',
    system: 'GCS',
    chevronDown: Constants.thinChevronDown,
    modalLabelFirst: 'modalLabelFirst',
    modalLabelSecond: 'modalLabelSecond',
    addressTypeCode: 'addressTypeCode',
    alternateThankYouTitleLabel: 'alternateThankYouTitleLabel',
    alternateThankYouDescriptionLabel: 'alternateThankYouDescriptionLabel',
    continueReading: 'Continue reading'
});

export const SpotlightVideoPlayerConstants = Object.freeze({
    pgone: 'pgone',
    cloudinaryVideoPlayer: 'cloudinary-video-player',
    cloudinaryCore: 'cloudinary-core',
    inherit: 'inherit',
    never: 'never',
    sourceTypes: ['hls', 'mp4', 'ogg', 'webm'],
});

export const SpotlightStickyBarConstants = Object.freeze({
    stickyBarClassName: 'ob-SpotlightStickyBar',
    scrollTo: 'scrollTo',
    callToActionLink: Constants.callToActionLink,
    logoLink: 'logoLink',
    buyNowModal: 'buyNowModal',
    logo: 'logo',
    name: Constants.name,
    title: Constants.title,
    medium: Constants.medium,
    SignUp: 'Sign up',
    callToActionLabel: Constants.callToActionLabel,
    CallToActionLabel: 'CallToActionLabel',
});

export const SpotlightSliderConstants = Object.freeze({
    scrollerContainer: 'scroller-container ',
    classNames: Constants.classNames,
    continueReadingLabel: 'continueReadingLabel',
    main: 'main',
});

export const SpotlightPreorderConstants = Object.freeze({
    body: Constants.body,
    title: Constants.title,
    anchorId: Constants.anchorId,
    classNames: Constants.classNames,
    backgroundAsset: Constants.backgroundAsset,
    preOrderButtonLabel: 'preOrderButtonLabel',
    preOrderCallToAction: 'preOrderCallToAction',
    mobileBackgroundAsset: 'mobileBackgroundAsset',
});

export const SpotlightExperienceConstants = Object.freeze({
    description: Constants.description,
    title: Constants.title,
    anchorId: Constants.anchorId,
    classNames: Constants.classNames,
    backgroundAsset: Constants.backgroundAsset,
    callToActionLabel: 'callToActionLabel',
    callToActionLink: 'callToActionLink',
    experienceTitle: 'experienceTitle',
    experienceFeatures: 'experienceFeatures',
    closeLabel: 'closeLabel',
    chevronDown: Constants.chevronDown,
    countrySelectLabel: 'countrySelectLabel',
    countrySelectList: 'countrySelectList'
});

export const SpotlightExperienceProductConstants = Object.freeze({
    itemType: Constants.itemType,
    ProductName: 'ProductName',
    ratingSummary: 'inline_rating',
    button: Constants.button,
});


export const SpotlightContentVideoConstants = Object.freeze({
    introText: 'introText',
    introLogo: 'introLogo',
    title: Constants.title,
    titleLevel: Constants.titleLevel,
    defaultRight:"-720px",
    defaultBottom:"-170px",
    displaynone: "none",
    nextFrameOnVideoEnd: 'nextFrameOnVideoEnd',
    introDurationSecond: 'introDurationSecond',
    playButtonColor: 'playButtonColor',
    white: Constants.white,
    mobileFullSize: 'mobileFullSize',
    playLabel: 'playLabel',
    pauseLabel: 'pauseLabel',
    resetLabel: 'resetLabel',
    paused: 'paused',
    played: 'played',
    openTranscriptLabel: 'openTranscriptLabel',
    closeTranscriptLabel: 'closeTranscriptLabel',
    content: Constants.content,
    classNames: Constants.classNames,
    textContainerHasPadding: 'textContainerHasPadding',
    hide: 'hide',
    auto: 'auto',
    none: 'none',
    description: Constants.description,
    VideoId: 'VideoId',
    spotlightPause: 'spotlightPause',
    spotlightPlay: 'spotlightPlay',
    spotlightReplay: 'spotlightReplay',
    spotlightTranscript: 'spotlightTranscript',
    close: Constants.close,
});

export const SpotlightContentBlockConstants = Object.freeze({
    devicesConfigurations: 'devicesConfigurations',
    textAlign: 'textAlign',
    empty: Constants.empty,
    textAlignment: 'textAlignment',
    textContainerVerticalAlignment: 'textContainerVerticalAlignment',
    textContainerOffset: 'textContainerOffset',
    textContainerMarginRight: 'textContainerMarginRight',
    textContainerMarginLeft: 'textContainerMarginLeft',
    mainAsset: Constants.mainAsset,
    mainAssetHorizontalAlignment: 'mainAssetHorizontalAlignment',
    mainAssetMaxWidth: 'mainAssetMaxWidth',
    mainAssetMarginTop: 'mainAssetMarginTop',
    mainAssetMarginRight: 'mainAssetMarginRight',
    mainAssetOffsetBottom: 'mainAssetOffsetBottom',
    mainAssetOffsetLeft: 'mainAssetOffsetLeft',
    secondaryAsset: 'secondaryAsset',
    additionalAssetList: 'additionalAssetList',
    backgroundAsset: Constants.backgroundAsset,
    mainAssetScale: 'mainAssetScale',
    textFadeIn: 'textFadeIn',
    refreshImageOnFrameChange: 'refreshImageOnFrameChange',
    content: Constants.content,
    classNames: Constants.classNames,
    whiteText: Constants.whiteText,
    hasLink: 'hasLink',
    title: Constants.title,
    titleLevel: Constants.titleLevel,
    textImage: 'textImage',
    textImageMobile: 'textImageMobile',
    textImagePosition: 'textImagePosition',
    top: Constants.top,
    textContainerBackgroundColor: 'textContainerBackgroundColor',
    textContainerHasPadding: 'textContainerHasPadding',
    surtitle: 'surtitle',
    textContent: 'textContent',
    bottom: 'bottom',
    callToActionLink: Constants.callToActionLink,
    white: Constants.white,
    chevronRight: Constants.chevronRight,
    blue: Constants.blue,
    callToActionLabel: Constants.callToActionLabel,
    buyNowModal: 'buyNowModal',
});

export const SkipNavigationBlockConstants = Object.freeze({
    label: 'label',
    targetID: 'targetID',
    roleMain: '[role="main"]',
});

export const SideBySideContainerConstants = Object.freeze({
    anchorId: Constants.anchorId,
    name: Constants.name,
    left: 'left',
    right: 'right',
    sideBySide: 'side-by-side-',
    hasDivider: 'hasDivider',
    flipOnMobile: 'flipOnMobile',
    disclaimer: Constants.disclaimer,
    title: Constants.title,
    description: Constants.description,
    blocks: Constants.blocks,
    interdentalgreen: '#interdentalgreen',
    interdentalviolate: '#interdentalviolate',
    productOverviewAdded: 'productOverviewAdded',
});

export const ShowcaseBlockConstants = Object.freeze({
    classNames: Constants.classNames,
    title: Constants.title,
    description: Constants.description,
    sideImage: 'sideImage',
});

export const SeriesSelectorConstants = Object.freeze({
    master: 'master_',
    media: '(min-width: 768px)',
    dpr: 'f_auto,q_auto,w_398',
    empty: Constants.empty,
    ratingSummary: 'inline_rating',
    reviews: 'reviews',
});

export const SeriesTabsConstants = Object.freeze({
    button: Constants.button,
    true: 'true',
    false: 'false',
    chevronDown: Constants.chevronDown,
});
export const BuySeriesConstants = Object.freeze({
    chevronRight: Constants.chevronRight,
    buyNowTop: '_TOP'
});

export const SeriesComparisonItemConstants = Object.freeze({
    itemType: Constants.itemType,
    ProductName: 'ProductName',
    ratingSummary: 'inline_rating',
    button: Constants.button,
    comingSoonText: 'comingSoonText',
    commingSoonItem: 'coparison-item'
});

export const SeeMoreButtonConstants = Object.freeze({
    blue: Constants.blue,
    chevronLeft: 'chevronLeft',
    chevronRight: Constants.chevronRight,
    button: Constants.button,
});

export const AlgoliaConstants = Object.freeze({
    errorMsg: 'Cannot create algolia search client: ALGOLIA_APP_ID or ALGOLIA_APP_SEARCH_KEY envs are empty',
    crawlerTriggeredMsg:'Algolia crawler triggered!!'
});

export const algoliaReducerConstants = Object.freeze({
    SearchClicked : 'SearchClicked',
    queryParm : 'queryParm',
    searchStaticContent : 'searchStaticContent',
    hitsResponse : 'hitsResponse',
    filerClicked : 'filerClicked',
    pageNoClicked : 'pageNoClicked',
    showAutocomplete : 'showAutocomplete',
    searchValue : 'searchValue',
    isSpinning : 'isSpinning',
    totalPageNumber : 'totalPageNumber',
    filterItems : 'filterItems',
    nbHits : 'nbHits',
    nbPages : 'nbPages'
});

export const SearchBarConstants = Object.freeze({
    text: Constants.text,
    submit: Constants.submit,
    searchText: 'searchText',
    search: 'search',
    list: 'list',
    maximumAutocompleteResults: 10,
    searchTitle: 'searchTitle',
    common_search : 'COMMOM SEARCH',
    SearchClicked: 'SearchClicked',
    queryParm: 'queryParm',
    searchStaticContent: 'searchStaticContent',
    hitsResponse: 'hitsResponse',
    filerClicked: 'filerClicked',
    SearchSuggestions: 'SearchSuggestions',
    pageNoClicked: 'pageNoClicked',
    showAutocomplete: 'showAutocomplete',
    searchValue:'searchValue',
    isSpinning:'isSpinning',
    eventCategory: 'event_informational_action',
    eventAction: 'event_search'
});

export const RotationConstants = Object.freeze({
    mouseup: 'mouseup',
    grabbing: 'grabbing',
    number: 'number',
    grab: 'grab',
    match: 'TEXTAREA|INPUT|SELECT',
    touch: 'touch',
});

export const RichTextConstants = Object.freeze({
    text: Constants.text,
    classNames: Constants.classNames,
    anchorId: Constants.anchorId,
});

export const ResponsiveImageConstants = Object.freeze({
    tiny: 'tiny',
    mobile: 'mobile',
    tablet: 'tablet',
    desktop: 'desktop',
});

export const RecentlyViewedProductsBlockConstants = Object.freeze({
    show: 'show',
    fetch: '/_next/static/products/',
    json: '.json',
    four: '4',
});

export const PromoConstants = Object.freeze({
    promo: 'promo ',
    classNames: Constants.classNames,
    whiteText: Constants.whiteText,
    subtitle: 'subtitle',
    description: Constants.description,
    retailerText: 'retailerText',
    mobileBackgroundAsset: Constants.mobileBackgroundAsset,
    backgroundAsset: Constants.backgroundAsset,
    titleLevel: Constants.titleLevel,
    title: Constants.title,
    retailerLogo: 'retailerLogo',
    callToActionLink: Constants.callToActionLink,
    callToActionLabel: Constants.callToActionLabel,
    horizontalAlignment: 'horizontalAlignment',
    large: Constants.large,
});

export const ProductSubNavConstants = Object.freeze({
    classNames: Constants.classNames,
    scroll: Constants.scroll,
    anchorClick: 'anchorClick',
    resize: Constants.resize,
    wheel: 'wheel',
    touchmove: Constants.touchmove,
    top: Constants.top,
    undefined: 'undefined',
    Collapsed: 'COLLAPSED',
    Collapsing: 'COLLAPSING',
    chevronDown: Constants.chevronDown,
    chevronTop: Constants.chevronTop,
    medium: Constants.medium,
    blank: '_blank',
    links: 'links',
    h1: Constants.h1,
    h2: Constants.h2,
    small: Constants.small,
    mediumLong: Constants.mediumLong,
    initialLoad: 'initialLoad',
    buyNowTop: '_TOP_HEADER'
});

export const ProductOverlayConstants = Object.freeze({
    closeOverlayLabel: 'closeOverlayLabel',
    empty: Constants.empty,
    classNames: Constants.classNames,
    keydown: Constants.keydown,
    noScroll: 'noScroll',
    scroll: Constants.scroll,
    isActive: 'is-active',
    querySelectorAll: 'button:not([disabled]), a[href]:not([disabled])',
    Tab: Constants.Tab,
    Escape: Constants.Escape,
    close: Constants.close,
    priceSpiderSource: '//cdn.pricespider.com/1/lib/ps-widget.js',
    priceSpiderConsoleLogMessageError: 'pricespider script not loaded',
    buyNowLiteErrorLabel: 'buyNowLiteErrorLabel',
    activateBuyNowLite: 'activateBuyNowLite',
    BinID: '4210201401582',
    BinIDPINK: '4210201401643',
    RetailerName: 'AA Oralb JP Online Store'
});

export const ProductHighlightsConstants = Object.freeze({
    isActive: 'is-active',
    empty: Constants.empty,
    minus6: 'minus6',
    blue: Constants.blue,
    button: Constants.button,
    plus: 'plus',
    seeLess: 'SEE LESS',
    seeMore: 'SEE MORE',
    highlights: ' highlights',
    undefinedStr: 'undefined',
    buyNowTop: '_TOP'
});

export const ProductGridBlockConstants = Object.freeze({
    title: Constants.title,
    titleLevel: Constants.titleLevel,
    one: '1',
    two: '2',
    classNames: Constants.classNames,
    anchorId: Constants.anchorId,
    eyebrow: Constants.eyebrow,
    body: Constants.body,
    disclaimer: Constants.disclaimer,
    seeMoreLabel: 'seeMoreLabel',
    seeLessLabel: 'seeLessLabel',
    maxNumberInlineItems: 'maxNumberInlineItems',
    whiteText: Constants.whiteText,
    radialBackground: 'radialBackground',
    background: 'background',
    removeLazyLoad: 'removeLazyLoad',
    mobileViewAccordion: 'mobileViewAccordion',
    isDefaultOpenSeeMoreButton: 'isDefaultOpenSeeMoreButton',
    isDisableCssTransition: 'isDisableCssTransition'
});

export const PageNavigationConstants = Object.freeze({
    classNames: Constants.classNames,
    pageNavigation: 'page-navigation--underlined',
    placeholderTitleLabel: 'placeholderTitleLabel',
    smooth: Constants.smooth,
    empty: Constants.empty,
    chevronDown: Constants.chevronDown,
    blocks: Constants.blocks,
});

export const OnePageScrollConstants = Object.freeze({
    scrollDisabled: 'scroll--disabled',
    onePageScrollWrapper: 'one-page-scroll-wrapper',
    easeInOut: 'ease-in-out',
    resize: Constants.resize,
    hashChange: Constants.hashChange,
    touchmove: Constants.touchmove,
    keydown: Constants.keydown,
    container: 'container_',
    polite: 'polite',
    smooth: Constants.smooth,
    thinChevronDown: 'thinChevronDown',
    querySelector: '[data-isactiveblock="true"]',
    scrollDown: 'Scroll down'
});

export const BazaarVoiceConstants = Object.freeze({
    masterProductIdPrefix: 'master_',
    masterProductNamePrefix: 'master_product_'
});

export const ArticleShareConstants = Object.freeze({
    facebookAlt: 'Share on Facebook',
    twitterAlt: 'Share on Twitter',
    shareTitle: 'Share',
    facebookIcon: '/static/images/facebook.png',
    twitterIcon: '/static/images/twitter.png'
});

export const PokemonImages = Object.freeze({
    back:'/static/images/Back.png',
    delete:'/static/images/Delete.png',
    edit:'/static/images/Edit.png',
    bubble:'/static/images/Bubble.png',
    calloutImage:'/static/images/Callout_Image.png',
    backshotimage: '/static/images/brushback-img.jpg',
    pokemonImage: '/static/images/errors/Asset1.png',
    backimage: '/static/images/Back.png',
    EditImage: '/static/images/EditImage.png',
    Home: '/static/images/Home.png',
    homeIcon: '/static/images/errors/Home.png',
    Adchoice:'../../../static/images/icon1.png',
    unlockbackgroundimage: '/static/images/unlock-background-new.png',
    unlockbackgroundimageKids: '/static/images/unlock-background-pikachu.png',
    unlockbackgroundimageKidsNew: '/static/images/unlock-background-kids-new.png',
    startbrushdesk: '/static/images/start-brush-background-desktop-new.png',
    startbrushdeskKids: '/static/images/start-brush-pikachu.png',
    retriveimage: '/static/images/retrive.png',
    englishyay: '/static/images/yay_en.png',
    japinesyay: '/static/images/yay_jp.png',
    newYear: '/static/images/1280x800_NewYear.png',
    LifeDesign: '/static/images/1280x800_NewLifeDesign.png',
    Green: '/static/images/1280x800_Green.png',
    sumerimage: '/static/images/1280x800_Summer.png',
    Renewalxmas: '/static/images/1280x800_RenewalXmas.png',
    oralblogo: '/static/images/Oral-B-Logo.png',
    brushmaster: '/static/images/home_brusing_master.png',
    brushmasterKids: '/static/images/PokemonLogo.png',
    brushingtitle: '/static/images/brushing_master_desktop.png',
    oralbforgroundimages: '/static/images/oralb_foreground_new.png',
    oralbforgroundimagesKids: '/static/images/Pikachu28.png',
    settingIcon: '/static/images/White-Settings.svg',
    settingIconRef: '../../../static/images/White-Settings.svg',
    imageRevealArrow: '../../../static/images/image-reveal-arrow.svg',
    addBrushImage: '/static/images/Add_new_brush.svg',
    adChoiceEdit: '../../../../static/images/icon1.png',
    kidsBackground: '/static/images/kids/Asset-Background.png',
    kidsBackgroundNew: '/static/images/kids/Bg-with-stars-2x.png',
    kidsHomePageChar: '/static/images/kids/Asset-19.png',
    kidsRetriveimage: '/static/images/kids/retrive-char.png',
    kidsCalendarImage: '/static/images/kids/Green-character-1x.png',
    noStarImage: '/static/images/kids/No-star-1x.png',
    oneStarImage: '/static/images/kids/Half-star-gold-1x.png',
    twoStarImage: '/static/images/kids/Full-star-gold-1x.png',
    zeroStarImage: '/static/images/kids/Full-star-1x.png',
    loaderGif: '/static/images/kids/loader.gif',
    trackPathImage: '/static/images/kids/Green-path-2x.png',
    thankYouHalfStar: '/static/images/kids/Half-star-2x.png',
    thankYouFullStar: '/static/images/kids/Full-star-yellow-2x.png'
})
export const PypestreamChatConstants = Object.freeze({
    persistentChatTriggered: 'persistentChatTriggered_',
});

export const CampaignIframeOverlayConstants = Object.freeze({
    iframeOverlayTriggered: 'iframeOverlayTriggered',
});

export const BrushingMasterConstants = Object.freeze({
    checkBoxAriaLabel: 'Accept Consent',
    selectMonth: 'Select Month',
    selectYear: 'Select Year',
});

export const PressQuoteBlockConstants = Object.freeze({
    pressQuotes: 'pressQuotes',
    pressLogo: 'pressLogo',
    quote: 'quote',
    starRating: 'reviewStars',
    animationGifAsset: 'animationGifAsset',
    callToActionLabel: 'ctaLabel',
    callToActionLink: 'ctaLink',
    iconArrowLeft: 'chevronLeft',
    iconArrowRight: 'chevronRight',
    previousLabel: 'previousLabel',
    nextLabel: 'nextLabel',
    transparentWithBorders: 'Transparent with Borders',
});

export const SearchBlockConstants = Object.freeze({
    queryHashName: 'query',
    queryHashEqual: '=',
    faqStylePageLinks: 'Page Links',
    faqStyleDropdown: 'Dropdown',
    allResultsCategory: 'All',
    pageCategory: 'Page',
    collectionCategory: 'Collection',
    productCategory: 'Product',
    articleCategory: 'Article',
    searchText: 'searchText',
    faqCategory: 'FAQ'
});

export const CountryCode = Object.freeze({
    Belgique: 'BEL',
    France:'FRA',
    Deutschland: 'DEU',
    Schweiz:'CHE',
    'Österreich':'AUT',
    Nederland:'NLD',
    Belgie:'BEL',
    'United Kingdom':'GBR',
    'Ireland':'IRL'
});

export const SearchServiceConstants = Object.freeze({
    contentType: 'Content-Type',
    json: Constants.json,
    swiftypeBaseUrl: 'https://api.swiftype.com/api/v1',
    swiftypePath: '/public/engines/search',
    swiftypeAutocompleteUrl: 'https://search-api.swiftype.com/api/v1/public/engines/suggest.json'
});

export const JanrainConstants = Object.freeze({
    productListLocalStorageKeyPrefix: 'oralbProductList-',
    benefitListLocalStorageKeyPrefix: 'oralbBenefitPreferenceList-'
});

export const SvcApiConstants = Object.freeze({
    contentType: 'Content-Type',
    ciamInstanceId: 'CiamInstanceId',
    resendVerificationForm: 'resendVerificationForm'
});

export const WarrantyExtensionConstants = Object.freeze({
    contentType: 'Content-Type',
    json: Constants.json,
    accessToken: 'access_token',
    oneCPId: 'oneCPId',
    UUID: 'UUID',
    programIdParam: 'programId=',
    categoryIdParam: 'categoryId=',
    localeParam: 'locale=',
    warrantyExtensionPath: 'warrantyextension',
    getConsumerPath: 'consumer/getConsumer'
});

export const FaqDetailsBlockConstants = Object.freeze({
    classNames: 'classNames',
    anchorId: 'anchorId',
    baseClass: 'ob-faq-details-block '
});

export const IndexationUrlFactoryConstants = Object.freeze( {
    swiftypeBaseUrl: 'https://api.swiftype.com/api/v1/engines/',
    swiftypeDocumentTypes: '/document_types/',
    swiftypeAuth: '?auth_token=',
    json: '.json',
    specificSwiftypeDocument: '/documents/',
    generalSwiftypeDocument: '/documents'
});

export const RichTextLinkBlockConstants = Object.freeze({
    classNames: 'classNames',
    image: 'image',
    url: 'url',
    linkType: 'type',
    title: 'title',
});

export const BuyNowLiteConstants = Object.freeze({
    accessControlAllowOrigin: 'Access-Control-Allow-Origin',
    xFunctionKey: 'x-functions-key',
    passKey: 'passkey',
    ocpApimSubscriptionKey: 'Ocp-Apim-Subscription-Key',
    image: 'image',
    url: 'url',
    linkType: 'type',
    title: 'title',
});

export const HTMLConstants = Object.freeze({
    footerIframe: 'footer-iframe',
    DentalCIAncherLinkBlock: 'Dental-CI-Ancher-Link-Block',
    DentalCIHTMLSecondLinkimg: 'Dental-CI-HTML-Second-Link-img',
    obAccordionOne: 'ob-accordion-1',
    obMapBlock: 'ob-map-block'
});

export const ClicConstant = Object.freeze({
    campaignId: 25,
    templateId: 'd-a8ca66e55e204bd8b6039f4557020192'
});

export const ImageContenfullConstants = Object.freeze({
    defaultImageWindWidth: 450,
    defaultWidth: 400,
    defaultDesktopWidth:768,
    dpr_1440: 'f=center&q=100&w=1440',
    product: 'product',
    dpr_365: 'f=center&q=100&w=365',
    dpr_376: 'f=center&q=100&w=376',
    dpr_426: 'f=center&q=100&w=426',
    dpr_768: 'f=center&q=100&w=768',
    dpr_992: 'f=center&q=100&w=992',
    dpr_1200: 'f=center&q=100&w=1200',
    media_1200: '(min-width: 1200px)',
    media_992: '(min-width: 992px)',
    media_768: '(min-width: 768px)',
    media_376: '(min-width: 376px)'

});

export const ContentSeriesSelectorConstants = Object.freeze({
    master: 'master_',
    media: '(min-width: 768px)',
    dpr: 'f=center&q=100&w=398',
    empty: Constants.empty,
    ratingSummary: 'inline_rating',
    reviews: 'reviews',
});